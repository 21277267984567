/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line import/no-unresolved
import 'runtime';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { get } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'i18n';
import useLanguage from 'components/hooks/useLanguage';
import Text from 'components/Text/Text';
import Select from 'components/Select/Select';
import Tooltip from 'components/Tooltip/Tooltip';
import FooterWrapper from './FooterWrapper';

const contactEmailAddress = 'hello@hyperspread.com';

const getPrivacyPolicyURL = (lang = '') => {
  const prefixPath = 'https://storage.googleapis.com/hyperspread-prod-default-assets';

  return (
    {
      fr: `${prefixPath}/PRIVACY_POLICY_FRANCAIS.pdf`,
    }[lang] || `${prefixPath}/PRIVACY_POLICY_ENGLISH.pdf`
  );
};

/** @namespace LegalMentions */
const LegalMentions = () => null;

/** @namespace FooterContent */
const FooterContent = ({ t, isConnectionPages, languages, selectedLang, handleChange }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleContactEmailCopy = () => {
    // copy email to clipboard and show tooltip
    navigator.clipboard.writeText(contactEmailAddress);

    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 3000);
  };

  return (
    <>
      {!isConnectionPages && (
        <>
          <Text size="p2" className="footer__link footer__copyright">
            {`© ${moment().year()} ${t('footer-copyright')}`}
          </Text>
          <span className="footer__separator" />
        </>
      )}
      <Text size="p2" className="footer__link">
        <Link href={getPrivacyPolicyURL(selectedLang)}>
          <a target="_blank">{t('footer-privacy-policy')}</a>
        </Link>
      </Text>
      <span className="footer__separator" />
      {isConnectionPages && LegalMentions(t)}
      {/* when it's ready
       {isConnectionPages && (
          <>
            <span className="footer__separator" />
            <Text className="footer__link" size="p2">
              <Link as={`/${t('footer-terms-condition-route')}`} href="terms-conditions">
                {t('footer-cgv')}
              </Link>
            </Text>
          </>
        )}
      */}

      <Tooltip text={t('email_copied')} position="top" visible={isTooltipVisible}>
        <button onClick={handleContactEmailCopy} type="button">
          <Text className="footer__legal" size="p2">
            {t('footer-help-contact')}
          </Text>
        </button>
      </Tooltip>
      <Select
        options={languages}
        value={selectedLang}
        onChange={(valOption) => handleChange(valOption)}
        top
        appearance="lang"
        className="footer__select"
      />
    </>
  );
};

FooterContent.propTypes = {
  isConnectionPages: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      zenDesk: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedLang: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const Footer = ({ t, isConnectionPages, className }) => {
  const router = useRouter();
  const { language, setLanguage, availableLanguages } = useLanguage();

  const pathname = get(router, 'pathname', '');
  const authorizedRoutes = [
    '/',
    '/reset-password',
    '/forgot-password',
    '/create-account',
    '/help-contact',
  ].includes(pathname);

  const handleChange = async (lang) => {
    await setLanguage(lang);
    if (!authorizedRoutes) {
      router.push('/');
    }
  };

  return (
    <FooterWrapper connection={isConnectionPages} className={`footer ${className}`} id="footer">
      <FooterContent
        handleChange={handleChange}
        t={t}
        languages={availableLanguages}
        isConnectionPages={isConnectionPages}
        selectedLang={language || 'fr'}
      />
    </FooterWrapper>
  );
};

Footer.defaultProps = {
  isConnectionPages: false,
};

Footer.propTypes = {
  isConnectionPages: PropTypes.bool,
  t: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

Footer.getInitialProps = async () => ({
  namespacesRequired: ['footer'],
});

export default withTranslation('footer')(Footer);
