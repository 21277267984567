/* eslint-disable prettier/prettier, indent */
import styled from 'styled-components';
import devices from 'tokens/devices';

const FooterWrapper = styled.footer`
  position: ${({ connection }) => (connection ? 'absolute' : 'relative')};
  width: ${({ connection }) => (connection ? '472px' : '100%')};
  bottom: 0;
  padding: 16px 24px;
  box-sizing: border-box;
  background: ${({ connection, theme }) => !connection && theme.colors.white700};
  display: flex;
  justify-content: ${({ connection }) => (connection ? 'space-around' : 'center')};
  align-items: center;

  .footer {
    &__separator {
      display: inline-block;
      width: 3px;
      min-width: 3px;
      min-height: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${({ connection, theme }) =>
        connection ? theme.colors.white : theme.colors.text500};
      margin: ${({ connection }) => (connection ? '0 8px' : '0 16px')};
    }

    &__link {
      width: auto;
      color: ${({ theme }) => theme.colors.text500};
      cursor: pointer;
      font-family: var(--primary-font);
      font-weight: 500;

      ${({ connection, theme }) =>
        connection &&
        `
      width: min-content;
      color: ${theme.colors.white};
      font-family: var(--primary-font);
      font-weight: 500;
      `};
    }

    &__legal {
      width: auto;
      color: ${({ theme }) => theme.colors.text500};
      cursor: pointer;
      white-space: nowrap;
      font-family: var(--primary-font);
      font-weight: 500;

      ${({ connection, theme }) =>
        connection &&
        `
      width: min-content;
      color: ${theme.colors.white};
      font-family: var(--primary-font);
      font-weight: 500;
      `};
    }

    &__copyright {
      margin-right: 50px;
      font-family: var(--primary-font);
      font-weight: 500;
    }

    &__select {
      .button__content--text {
        ${({ connection, theme }) =>
          connection &&
          `
      width: min-content;
      color: ${theme.colors.text500};
      font-family: var(--primary-font);
      font-weight: 500;
      `};
      }
    }
  }

  .footer__select.select__list {
    z-index: 1;

    .button__content--text {
      margin: 2px 0 3px;
    }
  }

  .button__content {
    .select__input--display {
      font-family: var(--primary-font);
      font-weight: 500;
      text-transform: uppercase;
      ${({ connection, theme }) =>
        connection &&
        `
      color: ${theme.colors.white};
      font-family: var(--primary-font);
      font-weight: 500;
      `};
    }
  }

  .footer__link a:link,
  .footer__link a:visited,
  .footer__link a:hover,
  .footer__link a:active,
  .footer__legal a:link,
  .footer__legal a:visited,
  .footer__legal a:hover,
  .footer__legal a:active {
    color: currentColor;
    text-decoration: none;
  }

  @media ${devices.xsmall} {
    .button__content {
      .select__input--display {
        ${({ connection, theme }) =>
          connection &&
          `
      width: min-content;
      color: ${theme.colors.text500};
      font-family: var(--primary-font);
      font-weight: 500;
      `};
      }
    }
  }

  @media ${devices.tabletL}, ${devices.tablet}, ${devices.xsmall}, ${devices.mobile} {
    .button__content {
      .select__input--display {
        ${({ connection, theme }) =>
          connection &&
          `
          width: min-content;
          color: ${theme.colors.text500};
          font-family: var(--primary-font);
          font-weight: 500;
        `};
      }
    }
  }

  @media ${devices.xsmall}, ${devices.mobile}, ${devices.tablet}, ${devices.tabletL} {
    .footer {
      ${({ connection, theme }) =>
        connection &&
        `
      &__select {
        .button__content--icon:before {
          color: ${theme.colors.grey700};
        }
      }
      `}
    }
  }

  @media ${devices.desktop}, ${devices.desktopL} {
    .footer {
      ${({ connection, theme }) =>
        connection &&
        `
      &__select {
        .select__display {
          color: ${theme.colors.white};
        }

        .button__content--icon:before {
          color: ${theme.colors.white};
        }
      }
      `}
    }
  }

  @media ${devices.tablet}, ${devices.tabletL}, ${devices.xsmall}, ${devices.mobile} {
    ${({ connection, theme }) =>
      connection &&
      `
      background-color: ${theme.colors.white700};
      justify-content: center;

      .footer {
        &__link {
          color: ${theme.colors.text500};
          font-weight: 400;
          width: auto;
        }

        &__legal{
          color: ${theme.colors.text500};
          font-weight: 400;
          width: auto;
        }

        &__separator {
          background-color: ${theme.colors.text500};
        }
      }
    `};
  }

  @media ${devices.tablet}, ${devices.tabletL} {
    width: 100%;
    background: ${({ connection, theme }) =>
      connection ? theme.colors.white700 : theme.colors.white};
    position: ${({ connection }) => (connection ? 'initial' : 'relative')};
    .footer {
      &__copyright {
        margin-right: 30px;
        width: 150px;
      }
    }
  }

  @media ${devices.xsmall}, ${devices.mobile} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    position: ${({ connection }) => (connection ? 'initial' : 'relative')};

    .footer {
      &__link {
        width: auto;

        :not(:last-of-type) {
          margin: 0 0 16px;
        }
      }

      &__legal {
        width: auto;

        :not(:last-of-type) {
          margin: 0 0 16px;
        }
      }

      &__separator {
        display: none;
      }
    }
  }
`;

export default FooterWrapper;
