import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useRouter } from 'next/router';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useQuery, useMutation } from '@apollo/react-hooks';
import UPDATE_LAST_CONNECTION from 'graphql/mutations/updateLastConnection.graphql';
import { withTranslation } from 'i18n';
import clsx from 'clsx';
import useWidgetScript from 'components/hooks/useWidgetScript';
import useManifestLink from 'components/hooks/useManifestLink';
import useClarityScript from 'components/hooks/useClarityScript';
import getConfig from 'next/config';
import Meta from 'components/Meta';
import Footer from 'components/Footer';
import ConsentBanner from 'components/ConsentBanner';
import Title from 'components/Title/Title';
import Button from 'components/Button/Button';
import Logo from 'components/Logo';
import USER_EMAIL from 'graphql/queries/userEmail.graphql';
import ConnectionSkeletonWrapper from './ConnectionSkeletonWrapper';

const { publicRuntimeConfig } = getConfig();
const { zendeskWidgetUrl, zendeskWidgetId, environment, clientName } = publicRuntimeConfig;

const ConnectionSkeleton = ({
  t,
  isTextMiddlePosition,
  leftContentImage,
  bgImageColor,
  children,
  customTagLine,
}) => {
  /** @namespace isOneVision @private onevision @fallback return false */
  const isOneVision = true;

  const router = useRouter();
  const { data: userData, loading } = useQuery(USER_EMAIL, { fetchPolicy: 'network-only' });
  const isAuthenticated = Boolean(get(userData, 'me.email', ''));
  const pathname = get(router, 'pathname', '');
  const authorizedRoutes = [
    '/',
    '/reset-password',
    '/forgot-password',
    '/create-account',
    '/help-contact',
  ].includes(pathname);
  const [updateLastConnection] = useMutation(UPDATE_LAST_CONNECTION);

  useWidgetScript(zendeskWidgetUrl, zendeskWidgetId);
  useClarityScript(clientName, environment);
  useManifestLink();

  useDeepCompareEffect(() => {
    if (isAuthenticated && !loading && authorizedRoutes) {
      updateLastConnection();
      router.push('/');
    }

    return () => null;
  }, [authorizedRoutes, isAuthenticated, loading, t]);

  return (
    <ConnectionSkeletonWrapper
      isTextMiddlePosition={isTextMiddlePosition}
      leftContentImage={leftContentImage}
      bgImageColor={bgImageColor}
    >
      <Meta fromLoginPage />
      <div className="connection-skeleton__left-content">
        <div className="connection-skeleton__left-content--bgcolor bg-gradient-to-t from-black/30">
          <Title size="h1" className={clsx(isOneVision && '!normal-case')}>
            {customTagLine || (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('connection-left-content-title').replace(
                    // replace brackets with <span>
                    /\[(.*?)\]/g,
                    '<span class="text-yellow-400">$1</span>',
                  ),
                }}
              />
            )}
          </Title>
        </div>
      </div>
      <div className="connection-skeleton__main-content">
        <Button className="header-brand" appearance="simple" onClick={() => router.push('/', '/')}>
          <div className="relative m-4 h-20 w-full">
            <Logo isLoginPage align="right" />
          </div>
        </Button>
        {children}
      </div>
      <Footer isConnectionPages className="connection__footer" />
      <ConsentBanner />
    </ConnectionSkeletonWrapper>
  );
};

ConnectionSkeleton.defaultProps = {
  isTextMiddlePosition: false,
  customTagLine: null,
  bgImageColor: null,
};

ConnectionSkeleton.propTypes = {
  isTextMiddlePosition: PropTypes.bool,
  leftContentImage: PropTypes.string.isRequired,
  bgImageColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
  customTagLine: PropTypes.string,
};

ConnectionSkeleton.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

export default withTranslation('common')(ConnectionSkeleton);
