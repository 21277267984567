import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { destroyCookie } from 'nookies';
import { isEmpty } from 'lodash';
import { useApolloClient, gql } from '@apollo/react-hooks';
import { withTranslation } from 'i18n';
import { signIn as SsoSignIn } from 'next-auth/react';

import Title from 'components/Title/Title';
import Button from 'components/Button/Button';
import Input from 'components/CustomField/Formik/Input';

const USER_SSO = gql`
  query getUserSsoByEmail($email: String!) {
    getUserSsoByEmail(email: $email) {
      id
      provider
    }
  }
`;

const initialValues = {
  email: '',
};

const validationSchema = (t) =>
  Yup.object().shape({
    email: Yup.string().email(t('error-email')).required(t('error-email-required')),
    password: Yup.string().required(t('error-password-required')),
  });

const SsoConnectionForm = ({ t, setUseSso, setError, TopErrorComponent, BottomErrorComponent }) => {
  const client = useApolloClient();

  const handleLogin = async (e, { email }) => {
    e.preventDefault();
    await Promise.all([
      destroyCookie(null, 'client_id'),
      destroyCookie(null, 'superAdminJWT'),
      destroyCookie(null, 'jwToken'),
    ]);

    if (isEmpty(email)) return setError(t('error-need-email'));

    const { data } = await client.query({
      query: USER_SSO,
      variables: { email },
      fetchPolicy: 'no-cache',
    });
    const ssoToUse = data.getUserSsoByEmail;

    if (!ssoToUse) return setError(t('error-no-sso'));

    return SsoSignIn(ssoToUse.id);
  };

  return (
    <div className="m-auto mt-8 flex max-w-sm flex-col justify-center gap-8">
      <Title size="h3" className="m-auto !text-info-700">
        {t('connection-form-title')}
      </Title>
      <div className="h-px w-full bg-gray-300" />
      {TopErrorComponent}
      <Formik initialValues={initialValues} validationSchema={validationSchema(t)}>
        {({ values }) => (
          <form className="flex flex-col gap-6">
            <Input id="email" name="email" label={t('label-email')} required />
            <Button onClick={(e) => handleLogin(e, values)}>{t('connect-button')}</Button>
            {BottomErrorComponent}
            <div className="h-px w-full bg-gray-300" />
            <Button appearance="light" onClick={() => setUseSso(false)}>
              {t('use-other-method')}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

SsoConnectionForm.defaultProps = {
  TopErrorComponent: null,
  BottomErrorComponent: null,
};

SsoConnectionForm.propTypes = {
  t: PropTypes.func.isRequired,
  setUseSso: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  TopErrorComponent: PropTypes.node,
  BottomErrorComponent: PropTypes.node,
};

SsoConnectionForm.getInitialProps = async () => ({
  namespacesRequired: ['connectionform', 'common'],
});

export default withTranslation('connectionform')(SsoConnectionForm);
