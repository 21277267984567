import React from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';
import { setCookie } from 'nookies';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { withTranslation } from 'i18n';
import getConfig from 'next/config';
import QUERY_ME from 'graphql/queries/getBasicUserInfo.graphql';
import SSO_GOOGLE_LOGIN from 'graphql/mutations/ssoGoogleLogin.graphql';

const { publicRuntimeConfig } = getConfig();
const { googleClientId } = publicRuntimeConfig;

const LoginSSO = ({ t, authorizedDomainNames, setError }) => {
  // Only for client with only one Authorized Domain Names
  const hostedDomain = authorizedDomainNames.length === 1 ? authorizedDomainNames[0] : undefined;
  const router = useRouter();
  const client = useApolloClient();
  const [ssoGoogleLogin] = useMutation(SSO_GOOGLE_LOGIN);
  const redirectAfterLogin = router.query.redirectTo || '/';

  const sendLoginGAInfo = async (userId, clientId, salespointId) => {
    window.gtag('event', 'login', {
      event_category: 'login',
      event_action: 'loginSuccess',
      user_id: userId,
      dimension1: clientId,
      dimension2: userId,
      dimension3: salespointId,
    });
  };

  const onSuccess = async ({ profileObj, tokenId }) => {
    const query = { googleInfo: JSON.stringify(profileObj), googleToken: tokenId };
    const { data } = await ssoGoogleLogin({
      variables: {
        googleToken: tokenId,
      },
    });

    if (data.ssoGoogleLogin.jwToken) {
      setCookie(null, 'jwToken', data.ssoGoogleLogin.jwToken, {
        secure: true,
        path: '/',
      });
      setCookie(null, 'client_id', data.ssoGoogleLogin.clientId, {
        secure: true,
        path: '/',
      });
      const { data: userData } = await client.query({
        query: QUERY_ME,
        fetchPolicy: 'network-only',
      });
      const userDetails = userData?.me || {};
      const { clientId } = data.ssoGoogleLogin;
      const userId = userDetails.id;
      const salespointId = userDetails.selectedSalesPointId;
      await sendLoginGAInfo(userId, clientId, salespointId);
      router.push(redirectAfterLogin);
    } else {
      const mailDomainName = profileObj.email.split('@')[1];
      if (authorizedDomainNames.includes(mailDomainName)) {
        await router.push(
          {
            pathname: '/sso',
            query,
          },
          `/sso?${new URLSearchParams(query).toString()}`,
        );
      } else {
        setError(t('error-sso-connection-domains', { domain: mailDomainName }));
      }
    }
  };

  return (
    <div className="googleLogin">
      <GoogleLogin
        className="googleLogin"
        clientId={googleClientId}
        buttonText={t('connect-google')}
        onSuccess={onSuccess}
        cookiePolicy="single_host_origin"
        hostedDomain={hostedDomain}
      />
    </div>
  );
};

LoginSSO.propTypes = {
  t: PropTypes.func.isRequired,
  authorizedDomainNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  setError: PropTypes.func.isRequired,
};

LoginSSO.getInitialProps = async () => ({
  namespacesRequired: ['connectionform', 'common'],
});

export default withTranslation('connectionform')(LoginSSO);
