import React from 'react';
import PropTypes from 'prop-types';
import Login from 'screens/Login';
import { get } from 'lodash';
import USER_IS_AUTHENTICATED from 'graphql/queries/userIsAuthenticated.graphql';
import PROVIDER_TYPE from 'graphql/queries/providerType.graphql';
import { navigation } from 'components/UI/navigation';
import { SessionProvider } from 'next-auth/react';

const LoginPage = (props) => (
  <SessionProvider session={props.session}>
    <Login {...props} />
  </SessionProvider>
);

LoginPage.propTypes = {
  // eslint-disable-next-line react/require-default-props
  session: PropTypes.shape(),
  // disable default props because weird behavior happen when we pass null or {} as default.
};

function findFeaturePath(nav, findFeature) {
  if (findFeature === 'homepage') return '/home';

  const path = nav.find(({ feature }) => feature === findFeature)?.path;
  if (path) return path;

  return nav
    .filter((route) => route.children)
    .map((route) => findFeaturePath(route.children, findFeature))
    .find((_path) => _path);
}

export async function getServerSideProps(context) {
  const { data } = await context.req.apolloClient.query({
    query: USER_IS_AUTHENTICATED,
  });

  const isAuthenticated =
    Boolean(get(data, 'me.email', '')) && context.req.headers.cookie.includes('client_id=');

  const homepageFeature = get(data, 'me.client.homepageFeature', '');
  const roleSlug = get(data, 'me.role.slug', '');

  if (isAuthenticated) {
    if (['provider-admin', 'provider'].includes(roleSlug)) {
      const { data: providerData } = await context.req.apolloClient.query({
        query: PROVIDER_TYPE,
      });
      const types = providerData?.me.providerInfo?.linkedClients.map(
        (client) => client.providerType,
      );
      let destination = '/provider/personal-informations';
      if (types?.includes('brief')) destination = '/provider/briefs';
      if (types?.includes('mkp')) destination = '/provider/orders-to-be-processed';

      return {
        redirect: {
          destination,
          permanent: false,
        },
      };
    }

    if (roleSlug === 'super-admin') {
      return { redirect: { destination: '/super-admin/organizations', permanent: false } };
    }

    return {
      redirect: {
        destination: findFeaturePath(navigation, homepageFeature),
        permanent: false,
      },
    };
  }

  return { props: {} };
}

export default LoginPage;
