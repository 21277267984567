import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { BsInfoCircleFill } from 'react-icons/bs';
import clsx from 'clsx';

import BasicInput from 'components/Input/Input';
import BasicTooltip from 'components/Tooltip/Tooltip';
import Text from 'components/Text/Text';
import { isEmpty } from 'lodash';

export const MaxLengthInfo = ({ maxLength, value }) =>
  maxLength && (
    <Text
      size="p2"
      className={`${
        maxLength === value?.length ? '!text-primary-800' : '!text-gray-500'
      } mt-1 text-right`}
    >
      {value?.length || 0} / {maxLength}
    </Text>
  );

MaxLengthInfo.defaultProps = {
  maxLength: null,
  value: '',
};

MaxLengthInfo.propTypes = {
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const Label = ({ label }) =>
  label && (
    <p className="absolute -top-3 left-4 z-10 select-none rounded border-solid border-gray-500 bg-white px-1 font-primary text-sm text-gray-600">
      {label}
    </p>
  );

Label.defaultProps = { label: null };
Label.propTypes = { label: PropTypes.string };

export const Tooltip = ({ tooltip }) =>
  tooltip && (
    <div className="absolute right-5 top-2.5">
      <BasicTooltip position="right" text={tooltip}>
        <BsInfoCircleFill className="cursor-pointer text-xl text-primary-500" />
      </BasicTooltip>
    </div>
  );

Tooltip.defaultProps = { tooltip: null };
Tooltip.propTypes = { tooltip: PropTypes.string };

function Input({ id, type, label, tooltip, className, maxLength, ...props }) {
  if (!id && !props.name) throw new Error('Input need id or name from formik');

  const [field, meta, helpers] = useField({ name: props.name || id });

  const onChange = (ev) => {
    if (type === 'number') {
      return helpers.setValue(ev.target.value === '' ? null : parseFloat(ev.target.value));
    }

    return helpers.setValue(ev.target.value);
  };

  const value = [NaN, null].includes(field.value) ? '' : field.value;
  const error = useMemo(() => (!isEmpty(meta.error) ? meta.error.toString() : ''), [meta.error]);

  return (
    <div className={clsx(className, 'relative w-full')}>
      <Text className={`!font-medium ${error ? '!text-rose-600' : ''}`} as="label">
        {label}
      </Text>
      <BasicInput
        className={tooltip && 'pr-12'}
        id={id}
        type={type}
        maxLength={maxLength}
        {...field}
        touched={meta.touched}
        error={error}
        value={value}
        {...props}
        onChange={onChange}
      />
      <MaxLengthInfo maxLength={maxLength} value={value} />
      <Tooltip tooltip={tooltip} />
    </div>
  );
}

Input.defaultProps = {
  type: 'string',
  tooltip: null,
  className: null,
  maxLength: null,
  id: null,
  name: null,
};

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

export default Input;
