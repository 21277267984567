import styled from 'styled-components';
import devices from 'tokens/devices';

const ConnectionSkeletonWrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  position: relative;

  .connection-skeleton__left-content {
    width: 472px;
    height: 100%;
    background-image: url(${(props) => props.leftContentImage});
    background-size: cover;

    &--bgcolor {
      background: ${(props) => props.bgImageColor};
      height: 100%;
      width: 100%;
      display: flex;
      align-items: ${(props) => (props.isTextMiddlePosition ? 'center' : 'flex-start')};

      .title {
        padding: 48px 98px 0 48px;
        color: ${({ theme }) => theme.colors.white};
        line-height: 48px;
      }
    }
  }

  .connection-skeleton__main-content {
    width: 100%;
    flex: 1;
    padding: 0 32px;
    position: relative;
    overflow-y: auto;

    .header-brand {
      height: auto;
    }

    & > button:nth-child(1) {
      margin-left: auto;
    }
  }

  @media ${devices.tabletL}, ${devices.tablet}, ${devices.mobile}, ${devices.xsmall} {
    flex-direction: column;
    overflow: auto;

    .connection-skeleton__main-content {
      height: calc(100% - 72px);
      position: initial;
    }

    .connection-skeleton__left-content {
      display: none;
    }

    .connection-skeleton__main-content {
      img {
        margin: 0 auto;
      }
    }
  }

  @media ${devices.tabletL}, ${devices.tablet} and (orientation: landscape) {
    .connection-skeleton__main-content {
      height: auto;
    }
  }

  @media ${devices.mobile}, ${devices.xsmall} and (orientation: portrait) {
    .connection-skeleton__main-content {
      height: auto;
    }
  }
`;

export default ConnectionSkeletonWrapper;
