import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Link, withTranslation } from 'i18n';

import Title from 'components/Title/Title';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import GoogleLogin from 'components/GoogleLogin';

import Input from 'components/CustomField/Formik/Input';

const initialValues = {
  email: '',
  password: '',
};

/** @namespace isOneVision @private onevision @fallback return false */
const isOneVision = true;

const validationSchema = (t) =>
  Yup.object().shape({
    email: Yup.string().email(t('error-email')).required(t('error-email-required')),
    password: Yup.string().required(t('error-password-required')),
  });

const ConnectionForm = ({
  t,
  ssoGoogle,
  authorizedDomainNames,
  setUseSso,
  TopErrorComponent,
  BottomErrorComponent,
  setError,
  signIn,
}) => {
  /** @namespace SsoButton */
  const SsoButton = (tr) => (
    <Button appearance="light" onClick={() => setUseSso(true)}>
      {tr('connect-sso')}
    </Button>
  );

  return (
    <div className="m-auto flex max-w-sm flex-col justify-center gap-8">
      <Title size="h3" className="m-auto text-primary-500">
        {t('connection-form-title')}
      </Title>
      <div className="h-px w-full bg-gray-300" />
      {TopErrorComponent}
      <Formik initialValues={initialValues} validationSchema={validationSchema(t)}>
        {({ values }) => (
          <form className="flex flex-col gap-6">
            {isOneVision && (
              <>
                <Title size="h4" className="m-auto text-primary-500">
                  {t('renault-group')}
                </Title>
                {SsoButton(t)}
                <div className="h-px w-full bg-gray-300" />
                <Title size="h4" className="m-auto text-primary-500">
                  {t('external-users')}
                </Title>
                <Text>{t('only-no-ipn')}</Text>
              </>
            )}
            <Input id="email" name="email" data-cy="email" label={t('label-email')} required />
            <Input
              id="password"
              name="password"
              label={t('label-password')}
              type="password"
              required
              autoComplete="new-password"
              data-cy="password"
            />
            <Button
              onClick={(e) => {
                const email = values.email.trim();
                const password = values.password.trim();
                e.preventDefault();
                if (!email || !password) {
                  setError(t('error-message-connection'));
                } else {
                  signIn({ email, password });
                }
              }}
              appearance={isOneVision ? 'light' : 'primary'}
              type="submit"
            >
              {t('connect-button')}
            </Button>
            <Text className="text-info-500">
              <Link href="/forgot-password">{t('forgot-password')}</Link>
            </Text>
            {BottomErrorComponent}
            {!isOneVision && (
              <>
                <div className="h-px w-full bg-gray-300" />
                {ssoGoogle && (
                  <GoogleLogin authorizedDomainNames={authorizedDomainNames} setError={setError} />
                )}
                {SsoButton(t)}
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

ConnectionForm.defaultProps = {
  ssoGoogle: null,
  TopErrorComponent: null,
  BottomErrorComponent: null,
};

ConnectionForm.propTypes = {
  t: PropTypes.func.isRequired,
  setUseSso: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  TopErrorComponent: PropTypes.node,
  BottomErrorComponent: PropTypes.node,
  ssoGoogle: PropTypes.bool,
  authorizedDomainNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ConnectionForm.getInitialProps = async () => ({
  namespacesRequired: ['connectionform', 'common'],
});

export default withTranslation('connectionform')(ConnectionForm);
